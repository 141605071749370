import React from 'react';
import LayoutWrapper from '../components/LayoutWrapper/LayoutWrapper';
import design from '../config/design';
import { useStaticQuery, graphql } from 'gatsby';
import Title from '../components/Title/Title';
import { View } from '../components/View/View';
import Text from '../components/Text/Text';
import ClientCardList from '../components/ClientCard/ClientCardList';
import { BigView } from '../components/BigView/BigView';

function Suppliers() {
  const { suppliers } = useStaticQuery(
    graphql`
      query {
        suppliers: allDatoCmsSupplierPage {
          edges {
            node {
              subtitle
              title
              clientsSection {
                shortDescription
                name
                image {
                  url
                }
              }
            }
          }
        }
      }
    `
  );
  const supplierContent = suppliers.edges[0].node;

  return (
    <>
      <LayoutWrapper>
        <BigView>
          <View
            top={3.5}
            desktopLeft={6}
            desktopRight={6}
            mobileLeft={1}
            mobileRight={1}
            tabletLeft={3}
            tabletRight={3}
            bottom={2}
          >
            <Title
              color={design.colors.blue150}
              content={supplierContent.title}
            />
            <Text
              color={design.colors.dark}
              content={supplierContent.subtitle}
            />

            <View top={5} />
            <Title
              color={design.colors.blue150}
              content={'Nossos fornecedores'}
            />
            <View top={1.5} />
            <ClientCardList items={supplierContent.clientsSection} />
            <View top={5} />
          </View>
        </BigView>
      </LayoutWrapper>
    </>
  );
}

export default Suppliers;
