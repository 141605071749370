import React from 'react';
import styled from 'styled-components';
import design from '../../config/design';
import { View } from '../View/View';
import ReactMarkdownMain, * as ReactMarkdownAlt from 'react-markdown/with-html';

const ReactMarkdown = ReactMarkdownMain || ReactMarkdownAlt;

const StyledClientCard = styled.div`
  /* // THE CARD */
  .card {
    /* cursor: pointer; */
    background: #f5f5f5;
    box-shadow: 0 5px 10px 0 rgba(#000, 0.15);
    border-radius: 10px;
    overflow: hidden;
    margin-top: 1rem;
    @media screen and (min-width: ${design.breakpoints.mobile}px) {
      /* width: 100%; */
      height: 350px;
    }
    @media screen and (min-width: ${design.breakpoints.tablet}px) {
      width: 230px;
      height: 370px;
    }
  }

  .card-title {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 14px;
    color: ${design.colors.primaryBlue};
    width: 100%;
  }

  .card-intro {
    margin-top: 0.5rem;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: ${design.colors.dark};
  }
`;

const ClientImage = styled.div`
  min-width: 100%;
  background-image: url(${props => props.src});
  background-color: ${design.colors.white};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 5px;
  height: 141px;
`;

const ClientCard = props => {
  return (
    <StyledClientCard>
      <div className="card">
        <View left={1} right={1} top={1}>
          <div>
            <div>
              <ClientImage src={props.img} />
            </div>
          </div>
          <View top={1} />
          <h2 className="card-title">{props.title}</h2>
          <p className="card-intro">
            {/* max characteres: 110 */}
            <ReactMarkdown source={props.shortDescription} escapeHtml={false} />
          </p>
        </View>
      </div>
    </StyledClientCard>
  );
};
export default ClientCard;
