import React from 'react';

import ClientCard from './ClientCard';
import { GridContainer } from '../Grid/Grid';

function ClientCardList(props) {
  return (
    <GridContainer gridGap={10}>
      {props.items.map(item => (
        <>
          <ClientCard
            title={item.name}
            img={item.image.url}
            shortDescription={item.shortDescription}
          />
        </>
      ))}
    </GridContainer>
  );
}

export default ClientCardList;
