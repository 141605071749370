import styled from 'styled-components';
import design from '../../config/design';

export const GridContainer = styled.div`
  display: grid;
  @media screen and (min-width: ${design.breakpoints.mobile}px) {
    grid-template-columns: ${props =>
      props.size && !props.auto
        ? props.size + 'px'
        : props.auto
        ? props.auto
        : `250px`};
    justify-content: center;
    grid-gap: 10px;
  }
  @media screen and (min-width: ${design.breakpoints.tablet}px) {
    grid-template-columns: ${props =>
      props.size
        ? `repeat(auto-fit, minmax(${props.size}px, auto))`
        : 'repeat(auto-fit, minmax(250px, auto))'};
    justify-content: flex-start;
    grid-gap: 50px;
  }
  @media screen and (min-width: ${design.breakpoints.desktop}px) {
    grid-template-columns: ${props =>
      props.size
        ? `repeat(auto-fit, minmax(${props.size}px, auto))`
        : 'repeat(auto-fit, minmax(250px, auto))'};
    justify-content: ${props => (props.center ? `center` : `flex-start`)};
    grid-gap: ${props => (props.gridGap ? props.gridGap : `50px`)};
  }
`;
